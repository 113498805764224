import gql from "graphql-tag"
import debounce from "lodash.debounce"
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver, extend, localize } from "vee-validate";
import pt from 'vee-validate/dist/locale/pt_BR.json';
import { required } from 'vee-validate/dist/rules';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from "@/store"
import {
    BAlert,
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BCardBody,
    BSpinner,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BLink,
    BInputGroupAppend,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormFile,
    BInputGroup,
    BInputGroupPrepend,
    VBTooltip
} from 'bootstrap-vue'
import { getAuth } from '@/apollo'

localize('pt_BR', pt);
extend('required', required);

const GET_FATURAS = gql `
    query getFaturas (
        $codcolcfo: Int,
        $codcfo: String,
        $num_proces: Int,
        $nome_coligada: String,
        $offset: Int,
        $limit: Int,
        $order_by: [api_view_portal_corretor_faturas_order_by!]
    ) {
       api_view_portal_corretor_faturas(
            where: {
                _and: [
                    {codcolcfo: {_eq: $codcolcfo}},
                    {codcfo: {_eq: $codcfo}},
                    {
                        _or: [
                            {num_proces: {_eq: $num_proces}},
                            {nome_coligada: {_like: $nome_coligada}}
                        ]
                    }
                ]
            },
            order_by: $order_by,
            offset: $offset,
            limit: $limit
        ) {
            codcolcfo
            codcfo
            cod_empresa
            num_proces
            codcoligada
            idmov
            nome_coligada
            fantasia_coligada
            cnpj_coligada
            ordem_compra
            data_emissao
            valor
            status_processo
            status_processo_desc
            etapa_processo
            etapa_processo_desc
            status_oc
            status_oc_desc
            itens {
                codcoligada
                idmov
                num_proces
                contrato
                cliente_nome
                data_venda
                empreendimento_fantasia
                quadra
                lote
                sequencia
                parcela_quitacao
                valor_faturar
            }
        }
        api_view_portal_corretor_faturas_aggregate(
            where: {
                _and: [
                    {codcolcfo: {_eq: $codcolcfo}},
                    {codcfo: {_eq: $codcfo}},
                    {
                        _or: [
                            {num_proces: {_eq: $num_proces}},
                            {nome_coligada: {_like: $nome_coligada}}
                        ]
                    }
                ]
            },
        ) {
            aggregate {
                count
            }
        }
    }
`;

export default {

    components: {
        ToastificationContent,
        BCardText,
        BCard,
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormInput,
        BFormFile,
        BCardBody,
        BSpinner,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup,
        BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,
        BAlert
    },

    directives: {
        'b-tooltip': VBTooltip,
    },

    data() {
        return {
            revenues: null,
            nfeNumber: null,
            nfeFile: null,
            processToSend: null,
            nfeBase64: null,
            loading: false,
            loadingRevenues: false,
            perPage: 10,
            totalRows: 1,
            totalRows2: 1,
            currentPage: 1,
            currentPage2: 1,
            pageOptions: [10, 15, 20],
            required,
            sortBy: 'num_proces',
            sortDesc: true,
            sortDirection: 'desc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },

            fields: [{
                    key: 'nome_coligada',
                    label: 'Empresa',
                    tdClass: 'td-limit-empresa',
                    sortable: true
                },
                {
                    key: 'num_proces',
                    label: 'Processo',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    class: 'd-none d-sm-table-cell',
                    sortable: true
                },
                {
                    key: 'data_emissao',
                    label: 'Emissão',
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    formatter: value => { return this.formatDate(value) }
                },
                {
                    key: 'valor',
                    label: 'Valor R$',
                    sortable: true,
                    tdClass: 'text-right',
                    thClass: 'text-right',
                    formatter: value => { return this.formatValue(value) }
                },
                {
                    key: 'status_processo',
                    label: 'Status',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Detalhe',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    sortable: false
                },
            ],

            fields2: [{
                    key: 'contrato',
                    label: 'Contrato',
                    sortable: true
                },
                {
                    key: 'data_venda',
                    label: 'Data',
                    sortable: false
                },
                {
                    key: 'parcela_quitacao',
                    label: 'Parcela',
                    sortable: true
                },
                {
                    key: 'valor_faturar',
                    label: 'Valor',
                    sortable: true
                },
                {
                    key: 'cliente_nome',
                    label: 'Cliente',
                    sortable: false
                },
                {
                    key: 'empreendimento_fantasia',
                    label: 'Empreendimento',
                    sortable: false
                },
                {
                    key: 'quadra',
                    label: 'Quadra',
                    sortable: false
                },
                {
                    key: 'lote',
                    label: 'Lote',
                    sortable: false
                },
            ],
        }
    },

    created() {
        if (this.loggedUser) {
            this.getFaturas();
        }
    },

    methods: {

        async getFaturas() {
            this.loadingRevenues = true;

            this.$apollo.query({
                query: GET_FATURAS,
                variables: {
                    codcolcfo: parseInt(this.loggedUser.codcolcfo),
                    codcfo: this.loggedUser.codcfo,
                    num_proces: parseInt(this.filter) || 0,
                    nome_coligada: '%' + (this.filter || '') + '%',
                    offset: (this.perPage * this.currentPage) - this.perPage,
                    limit: this.perPage,
                    order_by: {
                        [this.sortBy || 'num_proces']: this.sortDesc ? 'desc' : 'asc'
                    }
                },
                fetchPolicy: 'no-cache' //'cache-first ou no-cache'
            })

            .then((data) => {
                this.loadingRevenues = false;
                this.revenues = data['data']['api_view_portal_corretor_faturas']
                this.totalRows = data["data"]["api_view_portal_corretor_faturas_aggregate"]["aggregate"]["count"] || 0
            })

            .catch(error => {
                this.loadingRevenues = false;
                this.showToast({
                    title: 'Erro!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: 'Erro ao obter Faturas! ' + error,
                }, 3000);
            })
        },

        convertToBase64() {
            let that = this
            var selectedFile = document.getElementById("inputFile").files;

            if (selectedFile.length > 0) {
                var fileToLoad = selectedFile[0];
                var fileReader = new FileReader();
                var base64;

                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    let re = 'data:application/pdf;base64,'
                    let str = base64
                    let newStr = str.replace(re, '')
                    that.nfeBase64 = newStr
                };

                fileReader.readAsDataURL(fileToLoad);
            }
        },

        sendNFe(bvModalEvt) {
            let that = this
            bvModalEvt.preventDefault()
            this.loading = true;
            let codcolcfo = parseInt(this.loggedUser.codcolcfo)
            let codcfo = parseInt(this.loggedUser.codcfo)
            this.validateNFe().then(function() {
                (async() => {
                    const token = await getAuth()
                    fetch("https://api.saobento.com.br/portal-corretor/send_document", {
                            "method": "POST",
                            "headers": {
                                "Content-Type": "application/json",
                                "Authorization": `${token}`,
                            },
                            "body": JSON.stringify({
                                "codcolcfo": codcolcfo,
                                "codcfo": codcfo,
                                "cod_empresa": 1,
                                "num_proces": that.processToSend,
                                "nota_fiscal": that.nfeNumber,
                                "documento_base64": that.nfeBase64
                            })
                        })
                        .then(() => {
                            that.$refs['nfeModal'].hide();
                            that.showToast({
                                title: 'Sucesso',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: 'Enviado com sucesso!',
                            }, 2000);

                            that.getFaturas();
                        })
                        .catch(err => {
                            that.showToast({
                                title: 'Erro',
                                icon: 'XCircleIcon',
                                variant: 'danger',
                                text: err,
                            }, 2000);
                            that.$refs['nfeModal'].hide();
                            that.getFaturas();
                        });

                    this.loading = false
                    this.nfeNumber = ''
                })();

            }, function() {
                that.loading = false;
            });
        },

        uploadNFe(process) {
            this.processToSend = process;
            this.$refs['nfeModal'].show();
            this.loading = false
            this.nfeNumber = null
        },

        showToast(toast, duration) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: toast.title,
                    icon: toast.icon,
                    variant: toast.variant,
                    text: toast.text
                },
            }, {
                timeout: duration
            });
        },

        validateNFe() {
            return new Promise((resolve, reject) => {
                this.$refs.nfeRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                        this.showToast({
                            title: 'Erro',
                            icon: 'XCircleIcon',
                            variant: 'danger',
                            text: 'Preencha todos os campos obrigatórios!',
                        }, 2000);
                    }
                })
            })
        },

        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatValue(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatDate(dateValue) {
            if (dateValue == "" || dateValue == null) {
                return null;
            }
            if (typeof dateValue != "string") {
                dateValue = String(dateValue);
            }
            let year = dateValue.substr(0, 4)
            let month = dateValue.substr(5, 2)
            let day = dateValue.substr(8, 2)

            return day + '/' + month + '/' + year;
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },

        clearFilter() {
            this.filter = ''
            this.filterFaturas()
        },

        filterFaturas: debounce(function() {
            this.currentPage = 1
            this.getFaturas()
        }, 1000),

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },

    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
        },
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            } else {
                return null
            }
        },
    },

    watch: {
        currentPage: {
            handler: function() {
                this.getFaturas()
            }
        },
        sortBy: {
            handler: function() {
                this.currentPage = 1
                this.getFaturas()
            }
        },
        sortDesc: {
            handler: function() {
                this.currentPage = 1
                this.getFaturas()
            }
        }
    }
}